import React from 'react'
import {graphql} from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import Header from "@elegantstack/solid-ui-blocks/src/Header/Block01/Block01";
import {Container} from "theme-ui";
import Divider from "@elegantstack/solid-ui-components/src/Divider";
import Footer from "@elegantstack/solid-ui-blocks/src/Footer/Block01/Block01";

const Impressum = props => {
    const {allBlockContent, allMarkdownRemark} = props.data
    const markdown = allMarkdownRemark.edges.filter(obj => obj.node.frontmatter.title === "Impressum")[0].node;

    const content = normalizeBlockContentNodes(allBlockContent?.nodes)
    return (
        <Layout {...props}>
            <Seo title='Impressum'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            <Header content={content['header']}/>
            <Divider space='5'/>

            {/* Blocks */}
            <Container>
                <div dangerouslySetInnerHTML={{__html: markdown.html}}></div>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}


export const query = graphql`
  query innerpageImpressum {
  allBlockContent(
      filter: { page: { in: ["shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
    allMarkdownRemark {
    edges {
      node {
        html
        frontmatter {
        title
      }
      }
    }
    }
  }
`


//
// allBlockContent(
//     filter: { page: { in: ["site/impressum", "shared"] } }
// ) {
//     nodes {
//     ...BlockContent
//     }
// }
export default Impressum